import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Strings from "src/shared/Strings";
import WorkflowEvents from "src/shared/gift-card/WorkflowEvents";
import template from "src/shared/gift-card/tango-brand-reviewal-modal-view-template.html?raw";

const TangoBrandReviewalModalView = Backbone.View.extend({
    initialize(options) {
        this.eventAggregator = options.eventAggregator;
        this.render();
    },
    attributes: {
        class: "action-view__positioner",
    },
    events: {
        "click .js-back": "back",
        "click .js-next": "next",
        "click .js-close": "close",
    },
    template: _.template(template),
    render() {
        const data = this.model.toJSON();
        data.Strings = Strings;
        this.$el.html(this.template(data));
        this.$el.find(".js-description").html(this.model.get("description"));
        this.$el.find(".js-disclaimer").html(this.model.get("disclaimer"));
        return this;
    },
    back(event) {
        event.preventDefault();
        this.eventAggregator.trigger(WorkflowEvents.BACK);
    },
    next(event) {
        event.preventDefault();
        this.eventAggregator.trigger(WorkflowEvents.BRAND_REVIEWED);
    },
    close(event) {
        event.preventDefault();
        this.eventAggregator.trigger(WorkflowEvents.CLOSE);
    },
});

export default TangoBrandReviewalModalView;
