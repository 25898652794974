import $ from "jquery";
import _ from "underscore";

function WorkflowLoader($root, rootClass, removeRoot) {
    const state = {
        currentView: null,
        currentWorkflowItem: null,
        stack: [],
    };
    const $body = $(document.body);
    rootClass = rootClass || "";
    this.load = function (workflowItem) {
        if (state.currentWorkflowItem) {
            state.currentView.remove();
            state.stack.push(state.currentWorkflowItem);
        } else {
            $root.addClass(rootClass);
        }
        state.currentWorkflowItem = workflowItem;
        state.currentView = state.currentWorkflowItem.createView();
        $body.scrollTop();
        $root.html(state.currentView.$el);
        $body.addClass("nonscrolling");
    };
    this.loadPrevious = function () {
        if (state.currentView) {
            state.currentView.remove();
            state.currentView = null;
        }

        if (_.isEmpty(state.stack)) {
            if (removeRoot) {
                $root.remove();
            } else {
                $root.removeClass(rootClass);
            }
            $body.removeClass("nonscrolling");
            return true;
        }
        const workflowItem = state.stack.pop();
        if (workflowItem) {
            state.currentWorkflowItem = workflowItem;
            state.currentView = state.currentWorkflowItem.createView();
            $(document.body).scrollTop();
            $root.html(state.currentView.$el);
        }

        return false;
    };
    this.close = function () {
        if (state.currentView) {
            state.currentView.remove();
            state.currentView = null;
        }

        state.currentWorkflowItem = null;
        state.stack = [];
        $body.removeClass("nonscrolling");

        $body.removeClass("nonscrolling");
        if (removeRoot) {
            $root.remove();
        } else {
            $root.removeClass(rootClass);
        }
    };
}

WorkflowLoader.Builder = function () {
    let _$rootElement;
    let _rootClass;
    let _removeRootElementWhenStackIsEmpty = false;

    this.withRootElement = function ($rootElement) {
        _$rootElement = $rootElement;
        return this;
    };

    this.withRootClass = function (rootClass) {
        _rootClass = rootClass;
        return this;
    };

    this.removeRootElementWhenStackIsEmpty = function () {
        _removeRootElementWhenStackIsEmpty = true;
        return this;
    };

    this.build = function () {
        return new WorkflowLoader(
            _$rootElement,
            _rootClass,
            _removeRootElementWhenStackIsEmpty
        );
    };
};

export default WorkflowLoader;
