import Backbone from "backbone";
import _ from "underscore";
import RewardCollection from "src/account-rewards/RewardCollection";
import RewardSupplierModel from "src/account-rewards/RewardSupplierModel";
import omissible from "src/shared/api/omissible";

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;
const TYPES = {
    CASH_BACK: "CASH_BACK",
    FIXED: "FIXED",
};
const CustomRewardModel = RewardSupplierModel.extend({
    type: RewardSupplierModel.REWARD_SUPPLIER_TYPES.CUSTOM_REWARD,

    defaults: {
        face_value: null,
        face_value_type: null,
        type: null,
        display_type: "CUSTOM_REWARD",
        reward_email_auto_send_enabled: true,
        auto_fulfillment_enabled: false,
        missing_fulfillment_alert_delay_ms: 0,
        missing_fulfillment_alert_enabled: false,
        missing_fulfillment_auto_fail_delay_ms: 0,
        missing_fulfillment_auto_fail_enabled: false,
        partner_reward_supplier_id: null,
        face_value_algorithm_type: TYPES.FIXED,
        cash_back_min: null,
        cash_back_max: null,
        component_ids: [],
        tags: [],
        state_transitions: {},
    },

    urlRoot: "/api/v2/reward-suppliers/custom-rewards",

    fetch() {
        const urlValue = this.url();
        this.url = () => {
            return urlValue.endsWith("built") ? urlValue : urlValue + "/built";
        };
        return Backbone.Model.prototype.fetch.call(this);
    },

    initialize() {
        this.set(
            "cash_back_percentage_formatted",
            this.get("cash_back_percentage") * 100
        );
    },

    parseRequest(method, model, options) {
        const pickAttributes = [
            "face_value_type",
            "face_value",
            "name",
            "type",
            "display_type",
            "reward_email_auto_send_enabled",
            "auto_fulfillment_enabled",
            "missing_fulfillment_alert_delay_ms",
            "missing_fulfillment_alert_enabled",
            "missing_fulfillment_auto_fail_delay_ms",
            "missing_fulfillment_auto_fail_enabled",
            "partner_reward_supplier_id",
            "face_value_algorithm_type",
            "cash_back_percentage",
            "cash_back_min",
            "cash_back_max",
            "limit_per_hour",
            "limit_per_day",
            "component_ids",
            "tags",
            "state_transitions",
        ];

        if (this.attributes.face_value_type === "") {
            this.attributes.face_value_type = null;
        }

        if (!this.attributes.partner_reward_supplier_id) {
            this.attributes.partner_reward_supplier_id = null;
        }

        if (this.attributes.limit_per_day === "") {
            this.attributes.limit_per_day = null;
        }

        if (this.attributes.limit_per_hour === "") {
            this.attributes.limit_per_hour = null;
        }

        this.attributes.missing_fulfillment_alert_delay_ms = daysToMilliseconds(
            this.attributes.missing_fulfillment_alert_delay
        );
        this.attributes.missing_fulfillment_alert_enabled =
            this.attributes.missing_fulfillment_alert_delay &&
            this.attributes.missing_fulfillment_alert_delay !== "";

        this.attributes.missing_fulfillment_auto_fail_delay_ms =
            daysToMilliseconds(
                this.attributes.missing_fulfillment_auto_fail_delay
            );
        this.attributes.missing_fulfillment_auto_fail_enabled =
            this.attributes.missing_fulfillment_auto_fail_delay &&
            this.attributes.missing_fulfillment_auto_fail_delay !== "";

        this.attributes.cash_back_percentage =
            this.attributes.cash_back_percentage_formatted &&
            this.attributes.cash_back_percentage_formatted / 100;

        const request = _.pick(this.attributes, pickAttributes);
        if (method === "create") {
            omissible.parseAttributes(request, _.omit(pickAttributes, "type"));
        } else if (method === "update") {
            omissible.parseAttributes(request, pickAttributes);
        }

        options.attrs = request;

        if (
            options.attrs.face_value_algorithm_type === TYPES.CASH_BACK &&
            !options.attrs.face_value_type
        ) {
            // Backend cannot accept "face_value" if it's "null".
            delete options.attrs.face_value;
            options.attrs.face_value_type = "USD";
        } else if (options.attrs.face_value_algorithm_type === TYPES.FIXED) {
            delete options.attrs.cash_back_percentage;
        }
    },

    loadChartData() {
        const rewardCollection = new RewardCollection(
            {},
            {
                filter: {
                    reward_supplier_id: this.get("id"),
                    limit: undefined,
                },
            }
        );

        return rewardCollection.fetchAllData();
    },
});

function daysToMilliseconds(days) {
    if (!days || days === "") {
        return 0;
    }

    return days * MILLISECONDS_IN_DAY;
}

CustomRewardModel.type =
    RewardSupplierModel.REWARD_SUPPLIER_TYPES.CUSTOM_REWARD;

export default CustomRewardModel;
