import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import UserSubscriptionType from "src/shared/notification-subscription/UserSubscriptionType";
import subscriptionService from "src/shared/notification-subscription/subscription-service";
import notificationService from "src/shared/notification/notification-service";
import RewardSupplierModel from "src/account-rewards/RewardSupplierModel";
import Strings from "src/shared/Strings";
import omissible from "src/shared/api/omissible";

const CouponModel = RewardSupplierModel.extend({
    type: RewardSupplierModel.REWARD_SUPPLIER_TYPES.MANUAL_COUPON,

    defaults: {
        face_value: null,
        face_value_type: null,
        coupon_count_warn_limit: null,
        partner_reward_supplier_id: null,
        default_coupon_expiry_date: null,
        minimum_coupon_lifetime: 14,
        limit_per_hour: null,
        limit_per_day: null,
        display_type: "MANUAL_COUPON",
    },

    urlRoot: "/api/v2/reward-suppliers/manual-coupons",

    parseRequest(method, model, options) {
        const pickAttributes = [
            "face_value_type",
            "face_value",
            "name",
            "coupon_count_warn_limit",
            "partner_reward_supplier_id",
            "default_coupon_expiry_date",
            "minimum_coupon_lifetime",
            "limit_per_hour",
            "limit_per_day",
        ];

        if (this.attributes.face_value_type === "") {
            this.attributes.face_value_type = null;
        }

        if (this.attributes.default_coupon_expiry_date === "") {
            this.attributes.default_coupon_expiry_date = null;
        }

        if (this.attributes.minimum_coupon_lifetime === "") {
            this.attributes.minimum_coupon_lifetime = 0;
        }

        if (!this.attributes.partner_reward_supplier_id) {
            this.attributes.partner_reward_supplier_id = null;
        }

        if (this.attributes.limit_per_hour === "") {
            this.attributes.limit_per_hour = null;
        }

        if (this.attributes.limit_per_day === "") {
            this.attributes.limit_per_day = null;
        }

        const request = _.pick(this.attributes, pickAttributes);

        if (method === "create") {
            omissible.parseAttributes(
                request,
                _.omit(pickAttributes, [
                    "face_value_type",
                    "coupon_count_warn_limit",
                ])
            );
        } else if (method === "update") {
            omissible.parseAttributes(request, pickAttributes);
        }

        options.attrs = request;
    },

    refill() {
        const deferred = $.Deferred();
        const coupons = this.get("coupons");
        if (coupons && coupons !== "") {
            post(`${this.url()}/rewards`, {
                coupons,
                success() {
                    const name = Strings.capitalize(this.get("name"));
                    notificationService.success(
                        `New coupons were manually added to ${name}.`
                    );
                    deferred.resolve();
                },
                error() {
                    deferred.fail();
                },
            });
        } else {
            deferred.resolve();
        }

        return deferred;
    },

    fetchSubscriptions() {
        const self = this;
        const deferred = $.Deferred();

        subscriptionService
            .getUsersBySubscription(UserSubscriptionType.REWARD_ALERTS, this.id)
            .done((subscriptions) => {
                self.set("subscriptions", subscriptions);
                deferred.resolve();
            });

        return deferred;
    },
});

CouponModel.type = RewardSupplierModel.REWARD_SUPPLIER_TYPES.MANUAL_COUPON;

export default CouponModel;

function post(url, data) {
    const options = {
        type: "POST",
        url,
        data: data || {},
    };

    return Backbone.ajax(options);
}
