import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import RewardEvents from "src/shared/rewards/RewardEvents";
import WorkflowEvents from "src/shared/gift-card/WorkflowEvents";
import ClientTangoSettingsAccountCollection from "src/shared/gift-card/ClientTangoSettingsAccountCollection";
import TangoBrandSelectionModalView from "src/shared/gift-card/TangoBrandSelectionModalView";
import TangoRewardCreationModalView from "src/shared/gift-card/TangoRewardCreationModalView";
import TangoBrandReviewalModalView from "src/shared/gift-card/TangoBrandReviewalModalView";
import "scss/tango-reward-creation-workflow/tango-reward-creation-workflow.scss";

const EVENTS = {
    COMPLETE: "complete",
};

function getOrCreateFirstAccount(callback) {
    const accounts = new ClientTangoSettingsAccountCollection();
    accounts.fetch({
        success() {
            if (accounts.isEmpty()) {
                accounts.create(
                    {
                        funds_amount_warn_limit: "2500",
                    },
                    {
                        success() {
                            callback(accounts.first());
                        },
                    }
                );
            } else {
                callback(accounts.first());
            }
        },
    });
}

export default {
    create(filterFunction, workflowLoader, mainEventAggregator) {
        const workflowData = new WorkflowData();
        const privateEventBus = _.extend({}, Backbone.Events);
        const workflow = new Workflow(workflowData, privateEventBus);

        getOrCreateFirstAccount((account) => {
            workflowData.setAccount(account);
        });

        privateEventBus.on(WorkflowEvents.BRAND_SELECTED, (tangoBrand) => {
            workflowData.setBrand(tangoBrand);
            workflowLoader.load(workflow.brandReviewal);
        });

        privateEventBus.on(WorkflowEvents.BRAND_REVIEWED, () => {
            workflowLoader.load(workflow.rewardCreation);
        });

        privateEventBus.on(WorkflowEvents.REWARD_CREATED, (reward) => {
            mainEventAggregator.trigger(RewardEvents.REWARD_SAVED);
        });

        privateEventBus.on(WorkflowEvents.BACK, () => {
            workflowLoader.loadPrevious();
        });

        workflowData.setMainEventAggregator(mainEventAggregator);
        workflowData.setFilterFunction(filterFunction);

        return workflow.brandSelection.createView();
    },
    EVENTS,
};

function WorkflowData() {
    (this.setBrand = function (brand) {
        this.brand = brand;
    }),
        (this.getBrand = function () {
            return this.brand;
        }),
        (this.setAccount = function (account) {
            this.account = account;
        }),
        (this.getAccount = function () {
            return this.account;
        }),
        (this.setMainEventAggregator = function (mainEventAggregator) {
            this.mainEventAggregator = mainEventAggregator;
        }),
        (this.getMainEventAggregator = function () {
            return this.mainEventAggregator;
        }),
        (this.setFilterFunction = function (filterFunction) {
            this.filterFunction = filterFunction;
        }),
        (this.getFilterFunction = function () {
            return this.filterFunction;
        });
}

function Workflow(workflowData, privateEventBus) {
    this.brandSelection = {
        createView() {
            return new TangoBrandSelectionModalView({
                eventAggregator: privateEventBus,
                filterFunction: workflowData.getFilterFunction(),
                mainEventAggregator: workflowData.getMainEventAggregator(),
            });
        },
    };
    this.brandReviewal = {
        createView() {
            const brand = workflowData.getBrand();
            if (!brand) {
                throw new Error("No brand to review");
            }
            return new TangoBrandReviewalModalView({
                model: brand,
                eventAggregator: privateEventBus,
            });
        },
    };
    this.rewardCreation = {
        createView() {
            const brand = workflowData.getBrand();
            const account = workflowData.getAccount();
            if (!brand) {
                throw new Error("Cannot create a reward without a brand");
            }
            if (!account) {
                throw new Error("Cannot create a reward without an account");
            }
            return new TangoRewardCreationModalView({
                eventAggregator: privateEventBus,
                brand,
                account,
            });
        },
    };
}
