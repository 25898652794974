import _ from "underscore";
import Backbone from "backbone";
import RewardModel from "src/account-rewards/RewardModel";
import pagination from "src/shared/pagination";

export default Backbone.Collection.extend(
    _.extend(
        {
            url: "/api/v2/rewards",
            model: RewardModel,
            setFilter(filter) {
                this.filter = filter;
            },
            getFilter() {
                return this.filter || {};
            },
            fetch() {
                return Backbone.Model.prototype.fetch.call(this, {
                    data: this.getFilter(),
                });
            },
        },
        pagination
    )
);
