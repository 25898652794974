import Backbone from "backbone";
import _ from "underscore";
import RewardSupplierModel from "src/account-rewards/RewardSupplierModel";
import notificationService from "src/shared/notification/notification-service";
import Strings from "src/shared/Strings";
import omissible from "src/shared/api/omissible";

const SalesforceCouponModel = RewardSupplierModel.extend({
    type: RewardSupplierModel.REWARD_SUPPLIER_TYPES.SALESFORCE_COUPON,

    defaults: {
        coupon_pool_id: null,
        face_value: null,
        face_value_type: null,
        balance_refill_amount: null,
        partner_reward_supplier_id: null,
        settings_id: null,
        display_type: "SALESFORCE_COUPON",
    },

    urlRoot: "/api/v2/reward-suppliers/salesforce-coupons",

    parseRequest(method, model, options) {
        let pickAttributes = [
            "face_value_type",
            "name",
            "balance_refill_amount",
            "face_value",
            "partner_reward_supplier_id",
            "limit_per_day",
            "limit_per_hour",
        ];
        if (method === "create") {
            pickAttributes = pickAttributes.concat(
                "id",
                "coupon_pool_id",
                "settings_id"
            );

            if (this.attributes.coupon_pool_id === "") {
                this.attributes.coupon_pool_id = null;
            }
        }

        if (this.attributes.face_value_type === "") {
            this.attributes.face_value_type = null;
        }

        if (!this.attributes.partner_reward_supplier_id) {
            this.attributes.partner_reward_supplier_id = null;
        }

        if (this.attributes.limit_per_day === "") {
            this.attributes.limit_per_day = null;
        }

        if (this.attributes.limit_per_hour === "") {
            this.attributes.limit_per_hour = null;
        }

        const request = _.pick(this.attributes, pickAttributes);

        if (method === "create") {
            omissible.parseAttributes(
                request,
                _.omit(pickAttributes, "face_value_type")
            );
        } else if (method === "update") {
            omissible.parseAttributes(request, pickAttributes);
        }

        options.attrs = request;
    },

    refill() {
        const options = {
            type: "POST",
            url: `${this.url()}/coupons`,
            data: {},
        };
        const name = Strings.capitalize(this.get("name"));

        return Backbone.ajax(options).done(() => {
            notificationService.success(
                `New coupons were uploaded to ${name}.`
            );
        });
    },
});

SalesforceCouponModel.type =
    RewardSupplierModel.REWARD_SUPPLIER_TYPES.SALESFORCE_COUPON;

export default SalesforceCouponModel;
