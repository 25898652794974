import Backbone from "backbone";
import _ from "underscore";
import omissible from "src/shared/api/omissible";

export default Backbone.Model.extend({
    urlRoot: "api:///v2/reward-suppliers/tango",

    defaults: {
        face_value_algorithm_type: "FIXED",
    },

    initialize(attributes, options) {
        this.min_value = options && options.min_value;
        this.set(
            "cash_back_percentage_formatted",
            this.get("cash_back_percentage") * 100
        );
    },

    sync(method, model, options) {
        const pickAttributes = [
            "utid",
            "account_id",
            "face_value_algorithm_type",
            "face_value",
            "cash_back_percentage",
            "cash_back_min",
            "cash_back_max",
            "partner_reward_supplier_id",
            "name",
            "limit_per_day",
            "limit_per_hour",
            "component_ids",
            "description",
        ];
        if (this.attributes.face_value === null) {
            this.attributes.face_value = this.min_value;
        }

        if (this.attributes.limit_per_day === "") {
            this.attributes.limit_per_day = null;
        }

        if (this.attributes.limit_per_hour === "") {
            this.attributes.limit_per_hour = null;
        }

        if (!this.attributes.cash_back_percentage) {
            this.attributes.cash_back_percentage = null;
        } else {
            this.attributes.cash_back_percentage =
                this.attributes.cash_back_percentage_formatted &&
                this.attributes.cash_back_percentage_formatted / 100;
        }
        const request = _.pick(this.attributes, pickAttributes);
        if (method === "create") {
            omissible.parseAttributes(request, pickAttributes);
        }

        options.attrs = request;

        return Backbone.sync.call(this, method, model, options);
    },
});
