import Backbone from "backbone";
import _ from "underscore";
import Uri from "jsuri";

const SubscriptionCollection = Backbone.Collection.extend({
    url() {
        const uri = new Uri("/api/v2/subscriptions");
        uri.addQueryParam("type", this.type);
        uri.addQueryParam("subtype", this.subtypeId);

        return uri.toString();
    },

    initialize(attributes, options) {
        if (options) {
            this.type = options.type;
            this.subtypeId = options.subtypeId;
        }
    },
});

SubscriptionCollection.create = function (type, subtypeId) {
    return new SubscriptionCollection(null, {
        type,
        subtypeId,
    });
};

function getUsersBySubscription(type, subtypeId) {
    const subscriptionCollection = SubscriptionCollection.create(
        type,
        subtypeId
    );
    return subscriptionCollection.fetch();
}

export default {
    getUsersBySubscription,
};
