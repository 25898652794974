import Backbone from "backbone";
import _ from "underscore";
import RewardSupplierModel from "src/account-rewards/RewardSupplierModel";
import omissible from "src/shared/api/omissible";

const RewardSupplierTangoModel = RewardSupplierModel.extend({
    type: RewardSupplierModel.REWARD_SUPPLIER_TYPES.TANGO_V2,

    urlRoot: "api:///v2/reward-suppliers/tango",

    defaults: {
        funds_amount_warn_limit: null,
        partner_reward_supplier_id: null,
        display_type: "TANGO_V2",
    },

    parseRequest(method, model, options) {
        const pickAttributes = [
            "name",
            "description",
            "partner_reward_supplier_id",
            "limit_per_hour",
        ];
        if (method === "update") {
            if (!this.attributes.partner_reward_supplier_id) {
                this.attributes.partner_reward_supplier_id = null;
            }

            if (this.attributes.limit_per_day === "") {
                this.attributes.limit_per_day = null;
            }

            if (this.attributes.limit_per_hour === "") {
                this.attributes.limit_per_hour = null;
            }

            options.attrs = _.pick(this.attributes, [
                "name",
                "description",
                "partner_reward_supplier_id",
                "limit_per_hour",
                "limit_per_day",
                "cash_back_percentage",
            ]);
            const request = _.pick(this.attributes, pickAttributes);

            if (method === "create") {
                omissible.parseAttributes(
                    request,
                    _.omit(pickAttributes, ["utid", "account_id"])
                );
            } else if (method === "update") {
                omissible.parseAttributes(request, pickAttributes);
            }

            options.attrs = request;
        }
    },
});

RewardSupplierTangoModel.type =
    RewardSupplierModel.REWARD_SUPPLIER_TYPES.TANGO_V2;

export default RewardSupplierTangoModel;
