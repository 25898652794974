import Backbone from "backbone";
import _ from "underscore";
import RewardSupplierModel from "src/account-rewards/RewardSupplierModel";
import omissible from "src/shared/api/omissible";

const PaypalPayoutModel = RewardSupplierModel.extend({
    type: RewardSupplierModel.REWARD_SUPPLIER_TYPES.PAYPAL_PAYOUTS,

    defaults: {
        face_value: null,
        face_value_type: null,
        coupon_count_warn_limit: null,
        partner_reward_supplier_id: null,
        merchant_token: null,
        face_value_algorithm_type: "FIXED",
        cash_back_percentage: 0,
        cash_back_min: null,
        cash_back_max: null,
        display_type: "PAYPAL_PAYOUTS",
    },

    urlRoot: "/api/v2/reward-suppliers/paypal-payouts",

    initialize() {
        this.set(
            "cash_back_percentage_formatted",
            this.get("cash_back_percentage") * 100
        );
    },

    parseRequest(method, model, options) {
        const pickAttributes = [
            "face_value_type",
            "face_value",
            "name",
            "description",
            "merchant_token",
            "face_value_algorithm_type",
            "cash_back_percentage",
            "cash_back_min",
            "cash_back_max",
            "partner_reward_supplier_id",
            "limit_per_day",
            "limit_per_hour",
        ];

        if (this.attributes.face_value_type === "") {
            this.attributes.face_value_type = null;
        }

        if (!this.attributes.partner_reward_supplier_id) {
            this.attributes.partner_reward_supplier_id = null;
        }

        if (!this.attributes.merchant_token) {
            this.attributes.merchant_token = null;
        }

        if (this.attributes.limit_per_day === "") {
            this.attributes.limit_per_day = null;
        }

        if (this.attributes.limit_per_hour === "") {
            this.attributes.limit_per_hour = null;
        }

        this.attributes.cash_back_percentage =
            this.attributes.cash_back_percentage_formatted &&
            this.attributes.cash_back_percentage_formatted / 100;

        const request = _.pick(this.attributes, pickAttributes);
        if (method === "create") {
            omissible.parseAttributes(
                request,
                _.omit(pickAttributes, ["name", "merchant_token"])
            );
        } else if (method === "update") {
            omissible.parseAttributes(request, pickAttributes);
        }

        options.attrs = request;
    },
});

PaypalPayoutModel.create = function (id) {
    return new PaypalPayoutModel({ id });
};

PaypalPayoutModel.type =
    RewardSupplierModel.REWARD_SUPPLIER_TYPES.PAYPAL_PAYOUTS;

export default PaypalPayoutModel;
