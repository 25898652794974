import Backbone from "backbone";

const BrandModel = Backbone.Model.extend({
    toJSON() {
        const json = Backbone.Model.prototype.toJSON.apply(this);
        json.cid = this.cid;
        return json;
    },
});

export default Backbone.Collection.extend({
    url: "/api/v2/reward-suppliers/tango/catalog",
    model: BrandModel,
});
