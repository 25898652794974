import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import WorkflowEvents from "src/shared/gift-card/WorkflowEvents";
import RewardSupplierTangoModel from "src/shared/gift-card/RewardSupplierTangoModel";
import notificationService from "src/shared/notification/notification-service";
import stickyAlertMessageService from "src/shared/alert-messages/sticky-alert-message-service";
import Strings from "src/shared/Strings";
import FormView from "src/shared/FormView";
import RadioGroupView from "src/shared/RadioGroupView";
import ToggleSectionView from "src/shared/ToggleSectionView";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import TooltipView from "src/shared/tooltip/TooltipView";
import tooltipConfigurations from "src/shared/gift-card/tooltip-configurations.json";
import template from "src/shared/gift-card/tango-reward-creation-modal-view-template.html?raw";

const FACE_VALUE_ALGORITHM_TYPE = {
    FIXED: "FIXED",
    CASH_BACK: "CASH_BACK",
};

const ViewModel = Backbone.Model.extend({
    defaults: {
        brand_name: null,
        items: [],
        selected_item: null,
    },
});
ViewModel.fromTangoBrand = function (brand) {
    const model = new ViewModel({
        brand_name: brand.get("brand_name"),
        items: _.sortBy(brand.get("items") || [], (item) => item.face_value),
    });
    if (model.get("items").length === 1) {
        const onlyChoice = model.get("items")[0];
        model.set("selected_item", onlyChoice);
        model.set("face_value", onlyChoice.face_value);
    }
    return model;
};
const TangoRewardCreationModalView = Backbone.View.extend({
    initialize(options) {
        this.eventAggregator = options.eventAggregator;
        this.viewModel = ViewModel.fromTangoBrand(options.brand);
        this.duplicatedRewardData = options.duplicatedRewardData;
        this.account = options.account;
        this.listenTo(
            this.viewModel,
            "change:selected_item",
            this.render.bind(this)
        );
        this.render();
    },
    attributes: {
        class: "action-view__positioner",
    },
    template: _.template(template),
    events: {
        "click .js-back": "back",
        "click .js-close": "close",
        "change .js-card-select": "selectCard",
    },
    render() {
        const data = _.extend(this.viewModel.toJSON(), {
            is_duplicate: this.duplicatedRewardData?.isDuplicated,
        });
        data.Strings = Strings;
        this.$el.html(this.template(data));
        this.$fixedAlgorithmCheckbox = this.$(
            `[value=${FACE_VALUE_ALGORITHM_TYPE.FIXED}]`
        );
        this.$dynamicValueCheckbox = this.$(
            `[value=${FACE_VALUE_ALGORITHM_TYPE.CASH_BACK}]`
        );
        if (this.viewModel.has("selected_item")) {
            const selectedItem = this.viewModel.get("selected_item");
            const reward = new RewardSupplierTangoModel(
                {
                    account_id: this.account.get("account_id"),
                    utid: selectedItem.utid,
                    face_value: selectedItem.face_value,
                },
                {
                    min_value: selectedItem.min_value,
                }
            );
            if (this.duplicatedRewardData) {
                _.each(this.duplicatedRewardData, (data, key) => {
                    if (
                        this.duplicatedRewardData.face_value_algorithm_type ===
                        FACE_VALUE_ALGORITHM_TYPE.FIXED
                    ) {
                        this.prefill(
                            key,
                            _.omit(this.duplicatedRewardData, [
                                "cash_back_percentage_formatted",
                                "cash_back_min",
                                "cash_back_max",
                            ])
                        );
                    } else {
                        this.prefill(
                            key,
                            _.omit(this.duplicatedRewardData, "face_value")
                        );
                    }
                });
            }
            new FormView.Builder()
                .withRootElement(this.$el.find("form"))
                .withModel(reward)
                .withStickyAlertMessageService(stickyAlertMessageService)
                .render();
            const self = this;
            reward.on("sync", () => {
                self.eventAggregator.trigger(
                    WorkflowEvents.REWARD_CREATED,
                    reward
                );
                this.close();
                notificationService.success(
                    "Your gift card was saved successfully."
                );
            });

            RadioGroupView.create(this.$("#js-value-wrapper"), reward);
        }
        this.delegateEvents();

        TooltipView.renderAll(this.$el, tooltipConfigurations);
        NotificationEllipsisView.renderAll(this.$el);
        ToggleSectionView.create(this.$("#js-advanced-section-wrapper"));

        return this;
    },

    toggleCheckboxes(rewardData) {
        this.$fixedAlgorithmCheckbox.prop(
            "checked",
            _.isEqual(
                this.$fixedAlgorithmCheckbox.val(),
                rewardData.face_value_algorithm_type
            )
        );
        this.$dynamicValueCheckbox.prop(
            "checked",
            _.isEqual(
                this.$dynamicValueCheckbox.val(),
                rewardData.face_value_algorithm_type
            )
        );
    },

    prefill(key, rewardData) {
        this.toggleCheckboxes(rewardData);
        this.$(`[name=${key}]`).val(rewardData[key]);
    },

    back(event) {
        event.preventDefault();
        this.eventAggregator.trigger(WorkflowEvents.BACK);
    },
    close() {
        this.eventAggregator.trigger(WorkflowEvents.CLOSE);
    },
    selectCard(event) {
        const selected = $(event.currentTarget).find("option:selected");
        const utid = selected.val();
        const selectedItem = _.findWhere(this.viewModel.get("items"), {
            utid,
        });
        this.viewModel.set("selected_item", selectedItem);
        this.viewModel.set("face_value", selectedItem.face_value);
    },
});

TangoRewardCreationModalView.create = function (
    eventAggregator,
    rewardSupplierTangoBrand,
    rewardSupplierTangoAccount,
    duplicatedRewardData
) {
    return new TangoRewardCreationModalView({
        eventAggregator,
        brand: rewardSupplierTangoBrand,
        account: rewardSupplierTangoAccount,
        duplicatedRewardData,
    });
};

export default TangoRewardCreationModalView;
