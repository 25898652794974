import Backbone from "backbone";
import $ from "jquery";
import _ from "underscore";
import TangoBrandCollection from "src/shared/gift-card/TangoBrandCollection";
import NotificationFlashView from "src/shared/notification/NotificationFlashView";
import template from "src/shared/gift-card/tango-brand-selector-view-template.html?raw";

function LazyLoader($container) {
    const $suppportedImages = $container.find("img[data-src-lazy-load]");
    this.load = function () {
        $suppportedImages.each((index, image) => {
            if (image.lazyLoaded) {
                return;
            }
            const $image = $(image);
            if (isInView($container, $image)) {
                $image.attr("src", $image.data("src-lazy-load"));
                image.lazyLoaded = true;
            }
        });
    };
    function isInView($container, $element) {
        const elementTop = $element.offset().top - $container.offset().top;
        const elementBottom = elementTop + $element.height();
        return (
            (elementTop < 0 && elementBottom > 0) ||
            (elementTop > 0 && elementTop <= $container.height())
        );
    }
}

function Filterer() {
    const references = {
        all: [],
    };
    this.add = function (key, $element) {
        const reference = normalize(key);
        if (!references[reference]) {
            references[reference] = [];
        }
        references[reference].push($element);
        references.all.push($element);
    };

    this.filter = function (key) {
        key = key || "all";
        key = normalize(key);
        if (key === "all") {
            $(references[key]).addClass("visible");
        } else {
            $(references.all).removeClass("visible");
            _.each(_.keys(references), (brand) => {
                const noSymbols = (word) => {
                    return word.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
                };
                if (brand.includes(key) || noSymbols(brand).includes(key)) {
                    $(references[brand]).addClass("visible");
                }
            });
        }
    };

    function normalize(string) {
        return string.toLowerCase();
    }
}

const EVENTS = {
    SELECTION: "selection",
};

function getContainerHeight(availableHeight) {
    const rowHeight = 200;
    const minRows = 1;
    const maxRows = 4;
    const rowCount = Math.floor(availableHeight / rowHeight);
    if (rowCount < minRows) {
        return minRows * rowHeight;
    }
    if (rowCount > maxRows) {
        return maxRows * rowHeight;
    }
    return rowCount * rowHeight;
}

const TangoBrandSelectorView = Backbone.View.extend(
    {
        initialize(attributes) {
            this.availableHeight = attributes.availableHeight;
            this.filterFunction = attributes.filterFunction;

            this.collection.on(
                "request",
                _.partial(this.render, "request").bind(this)
            );
            this.collection.on(
                "error",
                _.partial(this.render, "error").bind(this)
            );
            this.collection.on(
                "sync",
                _.partial(this.render, "sync").bind(this)
            );
            this.collection.fetch();
        },
        template: _.template(template),
        events: {
            "click .tango-brand": "selectBrand",
        },
        renderLoading() {
            this.$el.html(this.template({ isLoading: true }));
            NotificationFlashView.create(this.$(".js-brand-container"));
        },
        render(event) {
            const templateData = {
                isLoading: false,
                isError: false,
                brands: [],
            };
            switch (event) {
                case "request":
                    templateData.isLoading = true;
                    this.$el.html(this.template(templateData));
                    NotificationFlashView.create(this.$(".js-brand-container"));
                    break;
                case "error":
                    templateData.isError = true;
                    this.$el.html(this.template(templateData));
                    break;
                case "sync":
                    var filteredCollection = this.collection.toJSON();
                    if (this.filterFunction) {
                        filteredCollection = _.filter(
                            filteredCollection,
                            this.filterFunction
                        );
                    }

                    this.$el.html(
                        this.template(
                            _.extend(templateData, {
                                brands: filteredCollection,
                            })
                        )
                    );
                    var $container = $(".tango-brands");
                    var lazyLoader = new LazyLoader($container);
                    var filterer = new Filterer();
                    $(".tango-brand").each((index, element) => {
                        const key = $(element).data("brand-name");
                        filterer.add(key, element);
                    });

                    this.$("#search").keyup((e) => {
                        filterer.filter($(e.currentTarget).val());
                        lazyLoader.load();
                    });

                    $container.scroll(lazyLoader.load);
                    filterer.filter();
                    lazyLoader.load();
                    break;
                default:
                    throw new Error(`Cannot render unknown event: ${event}`);
            }
            this.setBrandContainerHeight();
        },

        setBrandContainerHeight() {
            const $brandContainer = this.$el.find(".js-brand-container");
            const height = getContainerHeight(this.availableHeight);
            $brandContainer.height(height);
        },

        selectBrand(event) {
            const $brand = $(event.currentTarget);
            this.$(".tango-brand").removeClass("selected");
            $brand.addClass("selected");
            const cid = $brand.data("cid");
            this.trigger(EVENTS.SELECTION, this.collection.get({ cid }));
        },
    },
    {
        EVENTS,
    }
);

TangoBrandSelectorView.create = function (
    $rootElement,
    availableHeight,
    filterFunction
) {
    return new TangoBrandSelectorView({
        el: $rootElement,
        collection: new TangoBrandCollection(),
        availableHeight,
        filterFunction,
    });
};

export default TangoBrandSelectorView;
