import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import RewardEvents from "src/shared/rewards/RewardEvents";
import Session from "js/Session";
import template from "src/account-rewards/workflow/reward-supplier-type-selector-view-template.html?raw";
import ConfirmationModalView from "src/shared/modals/ConfirmationModalView";
import couponImageUrl from "assets/account-rewards/rewards_icon2@2x.png";
import giftCardImageUrl from "assets/account-rewards/rewards_icon1@2x.png";
import customRewardImageUrl from "assets/account-rewards/rewards_icon3@2x.png";
import sfccImageUrl from "assets/account-rewards/rewards_icon4@2x.png";
import blackhawkImageUrl from "assets/account-rewards/blackhawk-network.png";

const DEMO_DATA_RETAIL_CLIENT_ID = "313324062";
const NOT_AVAILABLE_MESSAGE = `<p>This feature is only available with the extended program package.</p>
    <p>Please contact your CSM or email <a href='mailto:support@extole.com'>support@extole.com</a> for more details.</p>`;

const RewardSupplierTypeSelectorView = Backbone.View.extend(
    {
        template: _.template(template),

        events: {
            "click #js-back": "back",
            "click .js-choose-reward-supplier-type":
                "openNewRewardSupplierForm",
        },

        attributes: {
            class: "action-view__positioner",
        },

        initialize(attributes) {
            this.eventBus = attributes.eventBus;
            this.render();
        },

        render() {
            this.$el.html(
                this.template({
                    couponImageUrl,
                    giftCardImageUrl,
                    customRewardImageUrl,
                    sfccImageUrl,
                    blackhawkImageUrl,
                })
            );
            return this;
        },

        back() {
            this.eventBus.trigger(RewardEvents.BACK);
        },

        openNewRewardSupplierForm(event) {
            event.preventDefault();
            const selectedType = $(event.currentTarget).data("type");
            const clientId = Session.getInstance().getClientId();
            if (!selectedType) {
                return;
            }

            if (
                clientId === DEMO_DATA_RETAIL_CLIENT_ID &&
                selectedType === "BHN"
            ) {
                const modal = ConfirmationModalView.create(
                    "Contact Us",
                    NOT_AVAILABLE_MESSAGE
                );
                modal.on(ConfirmationModalView.EVENTS.CONFIRMED, () => {
                    modal.close();
                    return;
                });
            } else {
                this.eventBus.trigger(
                    RewardEvents.REWARD_SUPPLIER_TYPE_SELECTED,
                    selectedType
                );
            }
        },
    },
    {
        create(eventBus) {
            return new RewardSupplierTypeSelectorView({
                eventBus,
            });
        },
    }
);

export default RewardSupplierTypeSelectorView;
