import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import RewardEvents from "src/shared/rewards/RewardEvents";
import WorkflowEvents from "src/shared/gift-card/WorkflowEvents";
import TangoBrandSelectorView from "src/shared/gift-card/TangoBrandSelectorView";
import template from "src/shared/gift-card/tango-brand-selection-modal-template.html?raw";

const BUFFER = 300;

function getWindowHeight() {
    return $(window).height();
}

function getAvaialableSpaceForBrands() {
    return getWindowHeight() - BUFFER;
}

const TangoBrandSelectionModalView = Backbone.View.extend({
    initialize(options) {
        this.eventAggregator = options.eventAggregator;
        this.mainEventAggregator = options.mainEventAggregator;
        this.filterFunction = options.filterFunction;
        this.render();
    },
    attributes: {
        class: "action-view__positioner",
    },
    template: _.template(template),
    events: {
        "click .js-next": "next",
        "click .js-back": "back",
        "click .js-close": "close",
    },
    render() {
        this.$el.html(this.template({}));
        const selector = TangoBrandSelectorView.create(
            this.$(".js-brand-selector"),
            getAvaialableSpaceForBrands(),
            this.filterFunction
        );
        const self = this;
        selector.on(TangoBrandSelectorView.EVENTS.SELECTION, (brand) => {
            self.setSelection(brand);
            self.$(".js-next").prop("disabled", false);
        });
        this.delegateEvents();
        return this;
    },
    showProgress() {
        this.$(".js-progress").show();
    },
    hideProgress() {
        this.$(".js-progress").hide();
    },
    createSelector() {
        const self = this;
        this.hideProgress();
        const selector = TangoBrandSelectorView.create(
            this.$(".js-brand-selector"),
            getAvaialableSpaceForBrands(),
            this.filterFunction
        );
        selector.on(TangoBrandSelectorView.EVENTS.SELECTION, (brand) => {
            self.setSelection(brand);
            self.$(".js-next").prop("disabled", false);
        });
    },
    setSelection(brand) {
        this.selection = brand;
    },
    getSelection() {
        return this.selection;
    },
    showError() {
        this.$(".js-error").show();
    },
    next(event) {
        event.preventDefault();
        this.eventAggregator.trigger(
            WorkflowEvents.BRAND_SELECTED,
            this.getSelection()
        );
    },
    back(event) {
        if (this.mainEventAggregator) {
            this.mainEventAggregator.trigger(RewardEvents.BACK);
            this.close(event);
        }
    },
    close(event) {
        event.preventDefault();
        this.eventAggregator.trigger(WorkflowEvents.CLOSE);
    },
});

TangoBrandSelectionModalView.create = function (eventAggregator) {
    return new TangoBrandSelectionModalView({
        eventAggregator,
    });
};

export default TangoBrandSelectionModalView;
