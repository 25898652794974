import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import stickyAlertMessageService from "src/shared/alert-messages/sticky-alert-message-service";
import RewardSupplierArchiveModalView from "src/shared/modals/RewardSupplierArchiveModalView";
import RewardEvents from "src/shared/rewards/RewardEvents";
import Strings from "src/shared/Strings";
import FormView from "src/shared/FormView";
import ToggleSectionView from "src/shared/ToggleSectionView";
import TooltipView from "src/shared/tooltip/TooltipView";
import tooltipConfigurations from "src/shared/gift-card/tooltip-configurations.json";
import template from "src/shared/gift-card/tango-reward-edit-modal-view-template.html?raw";

const RewardSupplierTangoEditModal = Backbone.View.extend({
    initialize(attributes) {
        this.mainEventAggregator = attributes.mainEventAggregator;
        this.render();
    },
    template: _.template(template),
    events: {
        "click .js-delete": "showDeleteConfirmation",
        "click #js-back": "back",
    },
    render() {
        const data = this.model.toJSON();
        data.Strings = Strings;
        this.$el.html(this.template(data));
        this.model.once("sync", this.onSave.bind(this));
        new FormView.Builder()
            .withRootElement(this.$el.find("form"))
            .withModel(this.model)
            .withStickyAlertMessageService(stickyAlertMessageService)
            .render();

        TooltipView.renderAll(this.$el, tooltipConfigurations);
        NotificationEllipsisView.renderAll(this.$el);
        ToggleSectionView.create(this.$("#js-advanced-section-wrapper"));

        return this;
    },
    onSave() {
        this.mainEventAggregator.trigger(RewardEvents.REWARD_SAVED, this.model);
    },
    showDeleteConfirmation() {
        RewardSupplierArchiveModalView.show(
            this.model,
            this.mainEventAggregator
        );
    },
    back() {
        this.mainEventAggregator.trigger(RewardEvents.BACK);
    },
});

RewardSupplierTangoEditModal.create = function (
    rewardSupplierTango,
    mainEventAggregator
) {
    return new RewardSupplierTangoEditModal({
        model: rewardSupplierTango,
        mainEventAggregator,
    });
};

export default RewardSupplierTangoEditModal;
